import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import Section from "../components/shared/Section"
import Text from "../components/shared/Text"

const TermsAndConditions = () => {
  const style = {
    textAlign: "left",
    margin: `0 auto`,
    maxWidth: `70%`,
  }

  const Wrapper = styled.div`
    ${style}
  `

  return (
    <Layout>
      <Section title="Terms of Use">
        <Wrapper>
          <Text>
            The website you have entered (its sub-domains, affiliated websites,
            any mobile versions, and any services available therefrom) (the
            “Site”) is a copyrighted work owned and operated by Meteor Affinity,
            Inc. (“Meteor”, “we” or “us”).
          </Text>
          <Text size="small">This document was last updated on May 2020.</Text>
          <Text>
            BY ACCESSING OR USING THE SITE, YOU HEREBY AFFIRM THAT YOU HAVE THE
            RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THE FOLLOWING TERMS AND
            CONDITIONS (THE “TERMS”). THESE TERMS TOGETHER WITH THE PRIVACY
            POLICY (LOCATED HERE) CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU AND
            METEOR.
          </Text>
          <Text>
            IF YOU ARE UNDER 18 YEARS OLD, YOUR PARENT OR LEGAL GUARDIAN MUST
            READ, UNDERSTAND, AND AGREE TO THESE TERMS ON YOUR BEHALF PRIOR TO
            YOUR USE OF THE SITE. IF YOU DO NOT AGREE TO THESE TERMS OR HAVE NOT
            OBTAINED YOUR PARENT OR LEGAL GUARDIAN’S CONSENT TO AGREE TO THESE
            TERMS, DO NOT ACCESS OR USE THE SITE. IN NO EVENT MAY YOU USE THIS
            SITE IF YOU ARE UNDER THE AGE OF 13.
          </Text>
          <Text>
            YOUR USE OF THE SITE CONSTITUTES ACCEPTANCE OF THESE TERMS.
          </Text>
          <Text type="subtitle">1. CHANGES TO THE SITE TERMS</Text>
          <Text>
            Meteor reserves the right, at any time, to modify, suspend, or
            discontinue the Site (in whole or in part) at its sole discretion
            with or without notice to you. You agree that Meteor will not be
            liable to you or to any third party for any modification,
            suspension, or discontinuation of the Site or any part thereof.
          </Text>
          <Text>
            Meteor further reserves the right, at any time, to revise these
            Terms or to impose new terms and conditions with respect to access
            or use of the Site, the Content, or any other matter, in its sole
            discretion. Any modification to the Terms shall become effective
            when posted. ANY ACCESS OR USE OF THIS SITE OR ANY CONTENT BY YOU
            AFTER THE POSTING OF THE REVISED TERMS SHALL CONSTITUTE YOUR
            AGREEMENT TO SUCH REVISED TERMS. No modification to these Terms
            shall be valid or enforceable against Meteor unless expressly agreed
            to by Meteor in a writing signed by a duly authorized officer of
            Meteor.
          </Text>
          <Text type="subtitle">2. TERM AND TERMINATION</Text>
          <Text>
            These Terms will remain in full force and effect while you use the
            Site. Meteor may terminate these Terms or discontinue operation of
            the Site without notice to you, at any time and for any reason, in
            our sole discretion, without liability, including but not limited to
            if you breach any of these Terms. In the event of termination of
            these Terms with respect to you, you will no longer be authorized to
            access or use the Site or any Content.
          </Text>
          <Text>
            In the event of a termination of these Terms or termination of your
            access to and use of the Site, Sections 3 through 14 of these Terms
            shall survive and continue in full force and effect. Further, all
            rights granted by you shall remain in full force and effect and
            Meteor shall be permitted, but shall not have any obligation, to
            delete any of your personal data collected in the operation of the
            Site unless otherwise required by law.
          </Text>
          <Text type="subtitle">3. SITE LICENSE; SITE CONTENT</Text>
          <Text>
            <ul>
              <li>
                <strong>License: </strong> Subject to these Terms, Meteor grants
                you a non-transferable, non-exclusive, revocable, limited
                license to use and access the Site solely for your own personal,
                non-commercial use. Unauthorized access to or use of the Site or
                the Content is a breach of these Terms and may be a violation of
                law.
              </li>
              <li>
                <strong>Site Content:</strong> Unless specifically permitted
                herein, no information, materials, files, videos, or other
                content (collectively “Content”) comprising, contained in or
                distributed through the Site may be reproduced in any form or
                used by you without the prior written consent of Meteor. The
                Site and the Content found therein are the property of Meteor,
                its licensees and/or licensors. The Site and the Content are
                protected by copyright laws and international treaty provisions.
                You acknowledge that Meteor or its clients, business partners,
                licensees or licensors (as applicable) own and shall retain the
                exclusive right, title and ownership in and to all copyrights,
                trade secrets, trademarks and other intellectual property and
                proprietary rights in the Site and all Content. You agree not to
                use any automated means, including, without limitation, agents,
                robots, scripts, or spiders, to access, monitor or copy any part
                of the Site or any of the Content. You agree not to copy,
                modify, rent, lease, loan, sell, assign, distribute, reverse
                engineer, disassemble, decompile, attempt to obtain the source
                code of, grant a security interest in, publicly perform,
                publicly display, transfer or exploit the Site, the Content, any
                technology or software relating thereto, or any portion of any
                of the foregoing. All copyright, trademark, or other proprietary
                notices on the Site or any Content must be retained and
                displayed at all times.
              </li>
            </ul>
          </Text>
          <Text type="subtitle">
            DISCLAIMER OF WARRANTIES AND REPRESENTATIONS
          </Text>
          <Text>
            THE SITE AND THE CONTENT CONTAINED IN AND DISSEMINATED FROM THE SITE
            ARE PROVIDED “AS IS,” “AS AVAILABLE,” AND WITHOUT WARRANTY OR
            REPRESENTATION OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT
            NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY,
            NON-INFRINGEMENT, ACCURACY, AND FITNESS FOR A PARTICULAR PURPOSE
            (ALL OF SUCH IMPLIED WARRANTIES AND REPRESENTATIONS BEING HEREBY
            EXPRESSLY DISCLAIMED). YOU ASSUME THE ENTIRE RISK (i) AS TO YOUR
            ACCESS TO AND USE OF THE SITE, AND YOUR SELECTION AND USE OF ANY
            CONTENT OBTAINED THROUGH OR FROM THE SITE; AND (ii) THAT THE SITE
            AND/OR THE CONTENT WILL MEET YOUR REQUIREMENTS, BE ACCURATE OR
            RELIABLE, HAVE ANY LEVEL OF QUALITY OR MEET YOUR EXPECTATIONS.
            METEOR MAKES NO WARRANTY OR REPRESENTATION THAT YOUR ACCESS TO AND
            USE OF THE SITE OR THE CONTENT WILL BE UNINTERRUPTED, VIRUS FREE,
            ERROR-FREE OR COMPLETELY SECURE. YOU ARE SOLELY RESPONSIBLE FOR ANY
            DAMAGE TO YOUR COMPUTER SYSTEM HARDWARE OR SOFTWARE, OR FOR ANY LOSS
            OF DATA OR OTHER DAMAGES, RELATING TO YOUR USE OF THE SITE OR THE
            SITE OR CONTENT. NO ADVICE, INFORMATION OR CONTENT, WHETHER ORAL OR
            WRITTEN, OBTAINED BY YOU FROM, THROUGH OR IN CONNECTION WITH YOUR
            USE OF THE SITE OR THE CONTENT, WILL CREATE OR PROVIDE ANY WARRANTY
            OR REPRESENTATION ON THE PART OF METEOR OR ANY OF THE METEOR PARTIES
            (AS HEREINAFTER DEFINED).
          </Text>
          <Text>
            Please note that some jurisdictions may not allow the exclusion of
            implied warranties, so some of the above exclusions may not apply to
            you. In such jurisdictions, the liability of Meteor shall be limited
            to the greatest extent permitted by applicable law.
          </Text>
          <Text type="subtitle">5. USER CONDUCT; PROHIBITED ACTIVITIES</Text>
          <Text>
            You agree that you shall not post, publish, submit or otherwise
            disseminate through the Site any content or other information:
          </Text>
          <Text>
            <ul>
              <li>
                that is known by you to be false, inaccurate or misleading;
              </li>
              <li>
                that violates, infringes or misappropriates any third party’s
                copyright, patent, trademark, trade secret, right of privacy,
                right of publicity or other intellectual property or proprietary
                right;
              </li>
              <li>
                that violates any law, statute, regulation, rule or ordinance
                (including, but not limited to, those governing export control,
                consumer protection, unfair competition, anti-discrimination or
                false advertising)
              </li>
              <li>
                that is, or may reasonably be considered to be, defamatory,
                libelous, hateful, profane, abusive, racially, religiously, or
                otherwise biased or offensive, unlawfully threatening or
                unlawfully harassing to any individual or entity, or otherwise
                contains foul language;
              </li>
              <li>
                that contains any computer viruses, worms or other potentially
                damaging computer programs or files
              </li>
            </ul>
          </Text>
          <Text>In addition to the foregoing, you agree not to:</Text>
          <Text>
            <ul>
              <li>
                Download or upload any content or material that you know or
                reasonably should know cannot be legally obtained in such
                manner;
              </li>
              <li>
                Restrict or inhibit any other user from using and enjoying any
                area within the Site;
              </li>
              <li>
                Collect or store personal information about other users of the
                Site, or submit personal data on the Site without their express
                permission and authority to do so;
              </li>
              <li>
                Attempt to access or use the Site or the Content after your
                access or use has been terminated;
              </li>
              <li>
                Affect the way the Site displays Content (including any pages
                contained therein) other than through adjustments to your
                browser or display settings to facilitate your personal viewing
                of the Site;
              </li>
              <li>
                Use any automated means to access or use the Site or to collect
                any Content contained therein;
              </li>
              <li>
                Modify or create variant versions of the Meteor name, trademark
                or logos;
              </li>
              <li>Interfere with or disrupt the Site or the infrastructure;</li>
              <li>
                Forge headers or manipulate identifiers or other data in order
                to disguise the origin of any content transmitted through the
                Site or to manipulate your presence on the Site;
              </li>
              <li>
                Take any action that imposes an unreasonably or
                disproportionately large load on the Site or its infrastructure;
              </li>
              <li>
                Engage in any acts or omissions that could constitute a
                violation of applicable laws, statutes, regulations, rules or
                ordinances.
              </li>
            </ul>
          </Text>
          <Text>
            You agree to notify us if you suspect any activity in violation of
            these Terms and cooperate with our investigation of such violation.
          </Text>
          <Text type="subtitle">6. THIRD-PARTY LINKS</Text>
          <Text>
            The Site may contain links to third-party websites and services for
            third parties (collectively, “Third-Party Links”). Such Third-Party
            Links are not under the control of Meteor, and Meteor is not
            responsible for any Third-Party Links. Meteor provides access to
            these Third-Party Links only as a convenience to you, and does not
            review, approve, monitor, endorse, warrant, or make any
            representations with respect to Third-Party Links. Your interaction
            with all Third-Party Links is at your own risk. When you click on
            any of the Third-Party Links, the applicable third party’s terms and
            policies apply, including the third party’s privacy and data
            gathering practices.
          </Text>
          <Text type="subtitle">7. COPYRIGHT AND TRADEMARK INFORMATION</Text>
          <Text>&copy; Meteor Affinity, Inc. 2020. All rights reserved.</Text>
          <Text>
            Your use of any trademarks, service marks, branding, logos, and
            designs owned or licensed by Meteor, its affiliates, or any of its
            clients is prohibited without the prior written consent of Meteor or
            the consent of the third party that owns the trademark.
          </Text>
          <Text type="subtitle">8. PRIVACY</Text>
          <Text>
            Certain information collected from you or about you in the course of
            your using the Site is subject to our{" "}
            <a
              href="https://www.meteoraffinity.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy (located HERE)
            </a>
            , which is incorporated into these Terms by reference and may be
            revised from time to time as provided therein. You acknowledge,
            agree and consent to the information collection, distribution and
            other terms, conditions and matters set forth in the Privacy Policy.
          </Text>
          <Text type="subtitle">9. NOTIFICATIONS</Text>
          <Text>
            We may be required by state or federal law to notify you of certain
            events. You hereby acknowledge and consent that such notices will be
            effective upon our posting them on the Site or delivering them to
            you through email, if you have previously provided your email
            address to us. If you do not provide us with accurate information,
            we cannot be held liable if you do not receive notice.
          </Text>
          <Text type="subtitle">10. GOVERNING LAW AND JURISDICTION</Text>
          <Text>
            The Site is controlled and managed by Meteor from its office in the
            United States and is targeted to the United States. These Terms
            shall be governed by and construed in accordance with the laws of
            the State of North Carolina, without reference to its conflicts of
            laws principles.
          </Text>
          <Text>
            You must not use the Site or the Content in countries where it is
            restricted, prohibited or limited by local law, regulations, codes
            or customs. Meteor makes no warranty or representation that the Site
            or the Content is appropriate or available for use in locations
            outside the United States
          </Text>
          <Text type="subtitle">11. USERS OUTSIDE OF THE UNITED STATES</Text>
          <Text>
            If you (a) are using the Site from a country embargoed by the United
            States, (b) are on the United States Treasury Department’s list of
            “Specially Designated Nationals,” or (c) are on the U.S. Commerce
            Department’s Table of Deny Orders, you agree that you will not
            conduct any commercial activities using or through the Site and will
            not otherwise use the Site or any related services in violation of
            United States export control laws or regulations.
          </Text>
          <Text type="subtitle">12. LIMITATION OF LIABILITY</Text>
          <Text>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL METEOR
            AND/OR THEIR RESPECTIVE OFFICERS, DIRECTORS, TRUSTEES, MEMBERS,
            MANAGERS, EMPLOYEES, AGENTS, IDENTIFIED SITE SPONSORS, OR
            REPRESENTATIVES (ALL OF THE FOREGOING, COLLECTIVELY, THE “METEOR
            PARTIES”) BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
            INCIDENTAL, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES (INCLUDING
            WITHOUT LIMITATION, DAMAGES FOR LOSS OF PERSONAL OR BUSINESS
            PROFITS, BUSINESS INTERRUPTION, LOSS OF PERSONAL OR BUSINESS
            INFORMATION, OR OTHER LOSS OR DAMAGE) ARISING OUT OF OR RELATED TO
            THESE TERMS OR ARISING OUT OF THE ACCESS OR USE, OR INABILITY TO
            ACCESS OR USE THE SITE AND/OR ITS CONTENT, EVEN IF THEY HAVE BEEN
            ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR IF SUCH DAMAGES ARE
            OTHERWISE FORESEEABLE, AND REGARDLESS OF THE NATURE OF THE CAUSE OF
            ACTION OR THEORY ASSERTED (E.G., WHETHER IN CONTRACT, TORT OR
            OTHERWISE). ACCESS TO, AND USE OF, THE SITE AND CONTENT IS AT YOUR
            OWN RISK AND DISCRETION AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY
            DAMAGE TO YOUR DEVICE OR COMPUTER, OR LOSS OF DATA RESULTING
            THEREFROM. IF, NOTWITHSTANDING THE PROVISIONS OF THE TERMS, ANY OF
            THE METEOR PARTIES IS FOUND TO BE LIABLE TO YOU FOR ANY DAMAGE OR
            LOSS WHICH ARISES OUT OF OR IS IN ANY WAY CONNECTED TO YOUR ACCESS
            TO OR USE OF THE SITE, THE CONTENT OR OTHERWISE, TO THE MAXIMUM
            EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY OF THE METEOR
            PARTIES SHALL IN NO EVENT EXCEED $100 USD. YOU AGREE TO USE YOUR
            BEST EFFORTS TO MITIGATE ANY DAMAGES OR LOSSES YOU MAY SUFFER IN
            CONNECTION WITH YOUR ACCESS TO AND USE OF THE SITE AND THE CONTENT.
          </Text>
          <Text>
            Because some jurisdictions do not allow the exclusion or limitation
            of liability for consequential or incidental damages, parts of the
            above limitation may not apply to you. In such jurisdictions, the
            liability of Meteor shall be limited to the greatest extent
            permitted by applicable law. Check your local laws for any
            restrictions or limitations regarding the limitation of liability
            for consequential or incidental damages.
          </Text>
          <Text type="subtitle">13. INDEMNIFICATION</Text>
          <Text>
            You agree to defend, indemnify and hold harmless Meteor, its
            affiliates, licensees, vendors, partners, identified Site sponsors,
            and each of their respective directors, officers, members, managers,
            employees, agents and representatives from and against any and all
            claims, actions, losses, damages, liabilities, judgments,
            settlements, costs and expenses (including, but not limited to,
            reasonable attorneys’ fees and court costs) arising out of or
            relating to (a) your breach of these Terms or any applicable laws or
            regulations, (b) your access to or use of the Site and/or the
            Content, (c) your violation, breach or misappropriation of a third
            party’s copyright, patent, trademark, trade secret, right of
            privacy, right of publicity, or other intellectual property,
            proprietary or other right, (d) your tortious acts including,
            without limitation, defamation, and/or (e) any claims you may raise
            against third parties relating to third party products or services.
            We reserve the right to assume, at our expense, the exclusive
            defense and control of any matter for which you are required to
            indemnify us, and you agree to cooperate with our defense of such
            claims. If we assume such defense, we will be responsible solely for
            our legal fees in connection with such defense and all other losses,
            damages, liabilities, judgments, settlements, costs and expenses
            shall be your sole responsibility.
          </Text>
          <Text type="subtitle">14. MISCELLANEOUS</Text>
          <Text>
            These Terms constitute the entire agreement between you and us
            regarding the use of the Site. Our failure to exercise or enforce
            any right or provision of these Terms shall not operate as a waiver
            of such right or provision. The section titles in these Terms are
            for convenience only and have no legal or contractual effect. If any
            provision of these Terms is, for any reason, held to be invalid or
            unenforceable, the other provisions of these Terms will be
            unimpaired and the invalid or unenforceable provision will be deemed
            modified so that it is valid and enforceable to the maximum extent
            permitted by law.
          </Text>
          <Text>
            If you have questions or comments, please contact us at:
            <a href="mailto:info@meteoraffinity.com">info@meteoraffinity.com</a>
          </Text>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export default TermsAndConditions
